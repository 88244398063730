import React, { useEffect, useRef, useState } from "react";
import "./AllInterview.css";
import Header from "../../../../components/Header/Header";
import Navbar from "../../../../components/Navbar/Navbar";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { Pagination } from "react-rainbow-components";
import { BsThreeDotsVertical } from "react-icons/bs";
import SelectedIcon from "../../../../assets/Icons/Interview/New/selectedIcon.svg";
import NotSelectIcon from "../../../../assets/Icons/Interview/New/rejectedIcon.svg";
import pinkBgCalendar from "../../../../assets/Icons/Interview/New/pinkBgCalendar.svg";
import pinkBgClock from "../../../../assets/Icons/Interview/New/pinkBgClock.svg";
import { NoInterview } from "../components/NoInterview";
import correctIcon from "../../../../assets/Icons/Interview/New/greenTick.svg";
import instantInterviewIcon from "../../../../assets/Icons/Interview/New/instantInterview.svg";
import threeDotsHorizontal from "../../../../assets/Icons/Interview/New/horizontalThreeDot.svg";
import ReactStars from "react-rating-stars-component";
import { Link, useHistory } from "react-router-dom";
import { BootcampBackend } from "../../../../backend";
import { LoadingShape } from "react-rainbow-components";
import moment from "moment";
import { Modal } from "react-bootstrap";
import Datetime from "react-datetime";
import TimePicker from "rc-time-picker";
import Cross from "../../../../assets/Icons/Interview/New/Cross.svg";
import LinkIcon from "../../../../assets/Icons/Interview/New/Link.svg";
import interviewLink from "../../../../assets/Icons/Interview/New/newLink.svg";
import Email from "../../../../assets/Icons/Interview/New/email.svg";
import linkedInIcon from "../../../../assets/Icons/Interview/LinkedIn_interview.svg";
import DateIcon from "../../../../assets/Icons/Interview/New/DateIcon.svg";
import Clock from "../../../../assets/Icons/Interview/New/clock.svg";
import dropDownIcon from "../../../../assets/Icons/Interview/dropDownPinkArrow.svg";
import Interview from "../../../../assets/Icons/Interview/New/interviewRed.svg";
import CreateInterview from "../../../../assets/Icons/Interview/New/createInterview.svg";
import Grid from "../../../../assets/Icons/Interview/New/grid.svg";
import ListView from "../../../../assets/Icons/Interview/New/listView.svg";
import greenTick from "../../../../assets/Icons/Interview/New/greenTick.svg";
import dropdownArrow from "../../../../assets/Icons/Interview/New/dropdownArrow.svg";
import ViewReport from "../../../../assets/Icons/Interview/New/viewReportIcon.svg";
import Select from "react-select";
import Delete from "../../../../assets/Icons/Settings/delete.svg";
import Edit from "../../../../assets/Icons/Settings/edit.svg";
import AddActive from "../../../../assets/Icons/addActive.svg";
import createInterviewImg from "../../../../assets/Icons/Interview/New/createInterviewImg.svg";
import searchIcon from "../../../../assets/Icons/expandableSearch.svg";
import PremiumIcon from "assets/Icons/Pricing/crown.svg";
import SubscriptionPlanScreen from "pages/PrivatePages/PricingPages/SubscriptionPlanScreen";
import { useDispatch, useSelector } from "react-redux";
import { increementInterviewCount } from "store/reducers/subscriptionSlice";
import { decreementInterviewCount } from "store/reducers/subscriptionSlice";
import { isAuthenticated } from "helper/user";
import LockIcon from "assets/Icons/lockIcon.svg";
import BlackCrown from "assets/Icons/block-crown.svg";
import CreateAssessmentWithWarningPopup from "pages/PrivatePages/Assessment/components/CreateAssessmentWithWarningPopup";
import ContactUs from "components/ContactUs/ContactUs";
import { useSidebar } from "helper/SideBarContext";
import { useLocation } from "react-router-dom";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#ffffff",
    color: "#3c3e4d",
    padding: 8,
    zIndex: 999,
    fontSize: "14px",
  }),
  control: (base, state) => ({
    ...base,
    minHeight: 40,
    backgroundColor: "#ffffff",
    borderBottom: "solid 1px #e4e4e4",
    borderTop: "none",
    borderLeft: "none",
    borderRight: "none",
    borderRadius: 0,
    fontSize: "14px",
    boxShadow: state.isFocused ? null : null,
    paddingLeft: 20,
    "&:hover": {
      borderColor: state.isFocused ? null : null,
    },
  }),
  placeholder: () => ({
    color: "#9d9fa6",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  dropdownIndicator: () => ({
    display: "none",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
  valueContainer: (provided, state) => ({
    ...provided,
    display: "flex",
  }),
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
// Functional components
export default function AllInterview() {
  const history = useHistory();
  const [interviewStatus, setInterviewStatus] = useState("pending");
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [allInterviews, setAllInterviews] = useState([]);
  const [clickedButton, setClickedButton] = useState("");
  const [numOfInterviews, setNumOfInterviews] = useState(0);
  const [show, setShow] = useState(false);
  const [modalInterview, setModalInterview] = useState(0);
  const [mailList, setMailList] = useState("email");

  const [endTime, setEndTime] = useState(
    moment(new Date()).add("30", "minutes")
  );
  const [startTime, setStartTime] = useState(moment(new Date()));
  const [instantLink, setInstantLink] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const [interviewTitle, setInterviewTitle] = useState("");
  const [candidateEmail, setCandidateEmail] = useState("");
  const [selectedDate, setSelectedDate] = useState(moment().format("ddd, ll"));
  const [interviewTitleError, setInterviewTitleError] = useState(false);
  const [candidateEmailError, setCandidateEmailError] = useState("");
  const [isCopied, setIsCopied] = useState("");
  const [editableItem, setIsEditableItem] = useState({});
  const [createRound, setCreateRound] = useState(false);
  const [isAllInterviews, setIsAllInterviews] = useState("allInterviews");
  const [viewText, setViewText] = useState("list");
  const [interviewRoundNo, setInterviewRoundNo] = useState(0);
  const [token, setInterviewToken] = useState("");
  const [showMsg, setShowMsg] = useState(false);
  const [copied, setCopied] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [interviewsCount, setInterviewsCount] = useState({
    upcoming: 0,
    completed: 0,
  });
  const [selectedCandidate, setSelectedCandidate] = useState("");
  const [Mhlist, setMhlist] = useState([]);
  const { isSidebarCollapsed } = useSidebar();
  const [tableMenuActive, setTableMenuActive] = useState("");
  const [showPlan, setShowPlan] = useState(false);
  const [
    showCreateInterviewWithWarningPopup,
    setShowCreateInterviewWithWarningPopup,
  ] = useState(false);
  const [showContactUs, setShowContactUs] = useState(false);
  const [isPageLoadedFirst, setIsPageLoadedFirst] = useState(true);
  const subscription = useSelector((state) => state.subscription);
  const dispatch = useDispatch();
  const wrapperRef = useRef(null);
  const StarterSubscriptionInterviewsLimit = 1;
  const query = useQuery();
  const interviewType = query.get("type");
  useOutsideAlerter(wrapperRef);
  const oninterviewTitleChange = (e) => {
    setInterviewTitle(e.target.value);
    setInterviewTitleError(false);
  };
  const onCandidateEmailChange = (e) => {
    setCandidateEmail(e.target.value);
    setCandidateEmailError("");
  };
  const handleMsgClose = () => {
    setShowMsg(false);
    setInterviewTitle("");
    setCandidateEmail("");
  };

  const handleClose = () => {
    setShow(false);
    setMailList("email");
    setModalInterview(0);
    setInterviewTitleError(false);
    setCandidateEmailError("");
    setIsCopied("");
  };
  const handleShow = () => {
    if (
      isAuthenticated()?.role === "admin_panel" ||
      subscription.totalCreatedInterviewCount <
        (subscription.subscriptionData?.mh_subscription_plan_id
          ?.liveInterviewCount || StarterSubscriptionInterviewsLimit)
    ) {
      setShow(true);
      setIsEditable(false);
    } else {
      if (
        subscription.totalCreatedInterviewCount >
        (subscription.subscriptionData?.mh_subscription_plan_id
          ?.liveInterviewCount || StarterSubscriptionInterviewsLimit)
      ) {
        setShowCreateInterviewWithWarningPopup(
          !showCreateInterviewWithWarningPopup
        );
      } else {
        setShowPlan(!showPlan);
      }
    }
  };
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setTableMenuActive("");
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  // Disable the past dates START
  let yesterday = moment().subtract(1, "day");
  let valid = function (current) {
    return current.isAfter(yesterday);
  };
  // Disable the past date END

  // Handle Date change Start
  const handleDateChange = (date) => {
    setSelectedDate(date.format("ddd, ll"));
  };
  // Handle Date change END

  const handleTimeChange = (date) => {
    setStartTime(date);
    setEndTime(moment(date).add(30, "minutes"));
  };

  const handleEndTimeChange = (date) => {
    setEndTime(date);
  };

  function ValidateEmail(userEmail) {
    var mailformat =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (userEmail.match(mailformat)) {
      return true;
    } else {
      return false;
    }
  }

  const createInstantInterview = () => {
    // Generate Interview Link & the call the API

    const headers = {
      headers: {
        Authorization: JSON.parse(localStorage.getItem("jwt")).token,
      },
    };

    axios
      .get(
        `${BootcampBackend}/interview/generateInterviewLink/instant`,
        headers
      )
      .then((result) => {
        if (result.status == 200) {
          setInstantLink(result.data.result.shortUrl);
          setInterviewToken(result.data.result.token);

          // Call the API
          axios
            .post(
              `${BootcampBackend}/interview/createInterview`,
              {
                interviewStartTime: new Date(),
                interviewType: "instant",
                interviewLink: result.data.result.shortUrl,
                interviewToken: result.data.result.token,
              },
              {
                headers: {
                  Authorization: JSON.parse(localStorage.getItem("jwt")).token,
                },
              }
            )
            .then((result) => {
              setModalInterview("instant");
              dispatch(increementInterviewCount());
              getAllInterviews(false);
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          console.log("Error in fetching the URL");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const createScheduledInterview = () => {
    const interviewStartTime = moment(
      selectedDate + " " + startTime.format("LTS")
    ).toDate();
    const interviewEndTime = moment(
      selectedDate + " " + endTime.format("LTS")
    ).toDate();

    // Generate Interview Link & then call the API
    // Call the API
    if (
      interviewTitle === "" &&
      candidateEmail === "" &&
      !isEditable &&
      !createRound
    ) {
      setInterviewTitleError(true);
      setCandidateEmailError("This field is required");
      return;
    } else if (!ValidateEmail(candidateEmail) && !isEditable && !createRound) {
      setCandidateEmailError("Enter valid email");
    } else if (isEditable) {
      const options = {
        interviewStartTime: interviewStartTime,
        interviewEndTime: interviewEndTime,
        email: candidateEmail,
        interviewTitle: interviewTitle,
      };

      const headers = {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("jwt")).token,
        },
      };

      axios
        .put(
          `${BootcampBackend}/interview/updateInterview/` +
            editableItem._id +
            "/" +
            editableItem.interviewRounds._id,
          options,
          headers
        )
        .then((result) => {
          handleClose();
          getAllInterviews();
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (createRound) {
      const options = {
        interviewStartTime: interviewStartTime,
        interviewEndTime: interviewEndTime,
        currentRound: interviewRoundNo,
        interviewlink: instantLink,
        interviewToken: token,
      };

      const headers = {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("jwt")).token,
        },
      };

      axios
        .post(
          `${BootcampBackend}/interview/createNewRound/` + editableItem._id,
          options,
          headers
        )
        .then((result) => {
          handleClose();
          getAllInterviews();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios
        .post(
          `${BootcampBackend}/interview/createInterview`,
          {
            interviewStartTime: interviewStartTime,
            interviewType: "scheduled",
            interviewLink: instantLink,
            interviewTitle: interviewTitle,
            interviewEndTime: interviewEndTime,
            email: candidateEmail,
            interviewToken: token,
          },
          {
            headers: {
              Authorization: JSON.parse(localStorage.getItem("jwt")).token,
            },
          }
        )
        .then((result) => {
          getAllInterviews(false);
          handleClose();
          setShowMsg(true);
          // setModalInterview("instant");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const deleteInterview = (data) => {
    // Update the page count on deleteing a interview

    axios
      .delete(
        `${BootcampBackend}/interview/deleteInterview/${data._id}/${data.interviewRounds._id}`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
        }
      )
      .then((result) => {
        // Update the num of pages in pagination
        fetchInterviewList().then((res) => {
          setTotalPages(Math.ceil(res.data.result.totalInterview / 10));
        });

        setTableMenuActive("");
        setAllInterviews(
          allInterviews.filter((x) => {
            if (x._id === data._id) {
              return x._id !== data._id;
            }
            return allInterviews;
          })
        );
        dispatch(decreementInterviewCount());
        getAllInterviews(false);

      })
      .catch((err) => {
        console.log(err);
      });
  };

  const copyLink = (data) => {
    setIsCopied(data);
    navigator.clipboard.writeText(instantLink);
  };

  useEffect(() => {
    if (!localStorage.getItem("jwt")) {
      history.push("/login");
    } else {
      getShortListedApplicant();
    }
  }, []);

  useEffect(() => {
    if(isPageLoadedFirst){
      getAllInterviews(interviewType === "my-interview" ? true : false,"completed", true);
    }
    
    getAllInterviews(interviewType === "my-interview" ? true : false);
  }, [activePage,interviewStatus,interviewType]);

  // Here
  const getShortListedApplicant = () => {
    const paramsData = {};
    const headers = {
      headers: {
        Authorization: JSON.parse(localStorage.getItem("jwt")).token,
      },
    };

    axios
      .get(
        `${BootcampBackend}/interview/getAllApplicantsShortlistedForInterview`,
        headers
      )
      .then((res) => {
        if (res.status == 200) {
          let MHUsers = res.data.result.data.filter((x) => {
            x.label = x.applicantId.Name;
            x.value = x.applicantId.Email;
            return x;
          });
          setMhlist(MHUsers);
        } else {
          console.log("Error in fetching the API");
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getAllInterviews = async (
    isMyInterview,
    status,
    isGetCompletedCount
  ) => {
    const paramsData = {};
    paramsData.current_page = activePage;
    paramsData.interviewStatus = status || interviewStatus;
    if (isMyInterview) {
      paramsData.interviews = "myInterview";
    }
    await axios
      .get(`${BootcampBackend}/interview/getInterviews`, {
        headers: {
          Authorization: JSON.parse(localStorage.getItem("jwt")).token,
        },
        params: paramsData,
      })
      .then((res) => {
        setIsPageLoadedFirst(false);
        setIsPageLoading(false);
        isMyInterview
          ? setIsAllInterviews("myInterviews")
          : setIsAllInterviews("allInterviews");
        if (!isGetCompletedCount) {
          setNumOfInterviews(res.data.result.totalInterview);
          setTotalPages(Math.ceil(res.data.result.totalInterview / 10));
          setAllInterviews(res.data.result.interviewData);
        }
        status || interviewStatus === "completed"
          ? setInterviewsCount((prev) => ({
              ...prev,
              completed: res.data.result.totalInterview,
            }))
          : setInterviewsCount((prev) => ({
              ...prev,
              upcoming: res.data.result.totalInterview,
            }));
      })
      .catch((err) => {
        setIsPageLoading(true);
        // show the error here.
        console.log(err);
      });
  };

  function openView(event, view) {
    let i, tabcontent, tablinks;

    setViewText(view);

    if (view == "calendar") {
      setIsPageLoading(true);
    }

    if (view == "calendar") {
      const backButton = document.querySelector(
        ".rbc-btn-group > button:not(:first-child):not(:last-child)"
      );
      const nextButton = document.querySelector(
        ".rbc-btn-group > button:last-child:not(:first-child)"
      );

      nextButton.click();

      setTimeout(() => {
        backButton.click();
      }, 500);
    }

    // Hide all the elements with class="tabcontent"
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }

    // Remove the active class from the buttons i.e. list view & card view.
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }

    // Display the current tab i.e. list or card by adding a active class to it.
    document.getElementById(view).style.display = "block";
    // event.currentTarget.className += "active";
  }

  function onCopy(textToCopy, index) {
    navigator.clipboard.writeText(textToCopy);
    setClickedButton(index);
  }

  function myFunction(e, item) {
    e.stopPropagation();
    if (tableMenuActive === item) {
      setTableMenuActive("");
    } else {
      setTableMenuActive(item);
    }
  }

  function generateScheduledLink() {
    const headers = {
      headers: {
        Authorization: JSON.parse(localStorage.getItem("jwt")).token,
      },
    };

    // Generate the scheduled link
    axios
      .get(
        `${BootcampBackend}/interview/generateInterviewLink/scheduled`,
        headers
      )
      .then((result) => {
        if (result.status == 200) {
          setInstantLink(result.data.result.shortUrl);
          setModalInterview("schedule");
          setInterviewToken(result.data.result.token);
          setInterviewTitle("");
          setCandidateEmail("");
          setSelectedDate(moment().format("ddd, ll"));
          setStartTime(moment(new Date()));
          dispatch(increementInterviewCount());
        } else {
          console.log("Error in fetching the API");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function reloadTable(name, isAllInterviews) {
    let element = document.getElementById(name);
    setIsAllInterviews(isAllInterviews);
    if (name == "upcoming") {
      setIsPageLoading(true);
      setInterviewStatus("pending");
      setActivePage(1);
    } else {
      setIsPageLoading(true);
      setInterviewStatus(name);
      setActivePage(1);
    }
  }
  const editSchedule = (item) => {
    setShow(true);
    setModalInterview("schedule");
    setIsEditable(true);
    setCreateRound(false);
    setIsEditableItem(item);

    setInterviewTitle(item.interviewTitle);
    setSelectedDate(
      moment(item.interviewRounds.interviewStartTime).format("ddd, ll")
    );
    setStartTime(moment(item.interviewRounds?.interviewStartTime));
    setEndTime(moment(item.interviewRounds.interviewEndTime));
    setCandidateEmail(item.candidateEmail);

    setInstantLink(item.interviewRounds.interviewLink);
  };

  const createAnotherRound = (e, interviews) => {
    e.stopPropagation();
    const headers = {
      headers: {
        Authorization: JSON.parse(localStorage.getItem("jwt")).token,
      },
    };

    // Generate the scheduled link
    axios
      .get(
        `${BootcampBackend}/interview/generateInterviewLink/scheduled`,
        headers
      )
      .then((result) => {
        if (result.status == 200) {
          setInstantLink(result.data.result.shortUrl);
          setModalInterview("schedule");
          setInterviewToken(result.data.result.token);
          setShow(true);
          setIsEditable(true);
          setIsEditableItem(interviews);
          // setInstantLink(interviews.interviewRounds.interviewLink);

          setInterviewTitle(interviews.interviewTitle);
          setSelectedDate(
            moment(interviews.interviewRounds.interviewStartTime).format(
              "ddd, ll"
            )
          );
          setStartTime(moment(interviews.interviewRounds?.interviewStartTime));
          setEndTime(moment(interviews.interviewRounds.interviewEndTime));
          setCandidateEmail(interviews.candidateEmail);
          setIsEditable(false);
          setCreateRound(true);
          setInterviewRoundNo(interviews.interviewRounds.round);
        } else {
          console.log("Error in fetching the API");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const startInterview = (data) => {
    window.open(`/interview-paper/${data.interviewRounds.interviewToken}`);
  };

  const viewReport = (e, data) => {
    e.stopPropagation();
    history.push(
      `/interviews/interview-report/${data._id}/${data.interviewRounds._id}`
    );
  };

  const handlePaginationChange = (event, page) => {
    setActivePage(page);
  };

  async function fetchInterviewList() {
    try {
      const paramsData = {};

      if (interviewStatus == "pending") {
        paramsData.interviewStatus = "pending";
      } else if (interviewStatus == "completed") {
        paramsData.interviewStatus = "completed";
      }

      const res = await axios.get(
        `${BootcampBackend}/interview/getInterviews`,
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("jwt")).token,
          },
          params: paramsData,
        }
      );
      return res;
    } catch (err) {
      console.log(err);
    }
  }
  const onCompletedInterviewChange = (interview) => {
    if (interviewStatus == "completed") {
      history.push(
        history.push(`/interviews/interview-process/${interview._id}`)
      );
    }
  };

  const handlenextInterviewerChange = (value) => {
    setSelectedCandidate(value);
    setCandidateEmail(value.value);
  };

  return (
    <div>
      <Header
        heading={
          isAllInterviews == "allInterviews"
            ? "All Interviews"
            : "My Interviews"
        }
      />
      <div className="container-fluid body-margin-top adjust-margin-top px-0">
        <div className="interview">
          <Navbar/>
          <div
            className={`position-relative ${
              isSidebarCollapsed ? "collapsed-content-page" : "content-page"
            }`}
          >
            {/* top bar for  interview starts here */}
            <div className="all-interview-container">
              {(interviewsCount.upcoming != 0 ||
                interviewsCount.completed != 0) && (
                <div className="button-container">
                  <div>
                    <button
                      className="createInterviewBtn align-items-center"
                      onClick={handleShow}
                    >
                      <img
                        className="ms-2 me-3 img-fluid"
                        src={CreateInterview}
                        alt="logo"
                        loading="lazy"
                      />
                      Create Interview
                    </button>
                  </div>
                  <div className="button-items center">
                    <ul className="mb-0">
                      <li
                        className={interviewStatus == "pending" ? "active" : ""}
                        onClick={() => reloadTable("upcoming", isAllInterviews)}
                      >
                        <a id="upcoming">
                          Upcoming <span>{interviewsCount.upcoming}</span>
                        </a>
                      </li>
                      <li
                        className={
                          interviewStatus == "completed" ? "active" : ""
                        }
                        onClick={() =>
                          reloadTable("completed", isAllInterviews)
                        }
                      >
                        <a id="completed">
                          Completed <span>{interviewsCount.completed}</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div>
                    {/* search API not there */}
                    {/* <ExpnadableSearchBar /> */}
                    <div className="dropdown-right viewType ms-1">
                      {viewText === "list" ? (
                        <img
                          className="cursor-pointer img-fluid"
                          onClick={() => openView(event, "card")}
                          src={Grid}
                          alt="logo"
                          loading="lazy"
                        />
                      ) : (
                        <img
                          className="cursor-pointer img-fluid"
                          onClick={() => openView(event, "list")}
                          src={ListView}
                          alt="logo"
                          loading="lazy"
                        />
                      )}
                    </div>
                    <div className="dropdown-right ms-1">
                      <button
                        className="no_of_rounds  no_background  tablinks"
                        onClick={() => setTableMenuActive("filterDropDown")}
                      >
                        <span className="filterTextInterview">
                          {isAllInterviews === "allInterviews" ? "All" : "My"}{" "}
                          Interviews
                        </span>
                        {/* <BsSliders /> */}
                        <img
                          src={dropDownIcon}
                          className="img-fluid ms-2"
                          alt="logo"
                          loading="lazy"
                        />
                      </button>
                      {tableMenuActive === "filterDropDown" && (
                        <div
                          className="dropdown-content-right show  random-class-1"
                          ref={wrapperRef}
                        >
                          <a
                            className="text-center"
                            onClick={() => {
                              setTableMenuActive("");
                              setIsPageLoadedFirst(true);
                              history.push("/interviews?type=my-interview");
                            }}
                          >
                            My Interviews
                          </a>
                          <a
                            className="text-center"
                            onClick={() => {
                              setTableMenuActive("");
                              setIsPageLoadedFirst(true);
                              history.push("/interviews");
                            }}
                          >
                            All Interviews
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              <div id="list" className="tabcontent ">
                {/* Table View START */}
                {allInterviews.length > 0 ? (
                  <>
                    <div className="listTablehld">
                      <table className="table mb-0">
                        <thead>
                          <tr key={allInterviews._id}>
                            <th scope="col" className="list-table-heading">
                              INTERVIEW TITLE
                            </th>
                            <th scope="col" className="list-table-heading">
                              CANDIDATE NAME
                            </th>
                            {interviewStatus == "completed" && (
                              <th scope="col" className="list-table-heading">
                                RATING
                              </th>
                            )}

                            <th scope="col" className="list-table-heading">
                              SCHEDULED ON
                            </th>
                            {isAllInterviews === "allInterviews" && (
                              <th scope="col" className="list-table-heading">
                                INTERVIEWER NAME
                              </th>
                            )}
                            <th scope="col" className="list-table-heading">
                              NO. OF ROUNDS{" "}
                            </th>
                            <th scope="col" className="list-table-heading"></th>
                            {interviewStatus !== "completed" && (
                              <th
                                scope="col"
                                className="list-table-heading"
                              ></th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colSpan="8" className="p-0 interview-plan-hld">
                              {/* both warning and error classes added here, kindly add the classname conditionally */}
                              {subscription.subscriptionData
                                ?.mh_subscription_plan_id?.name !==
                                "professional" &&
                              isAuthenticated()?.role !== "admin_panel" ? (
                                <div
                                  className={`d-flex upgrade-plan-hld w-100 mx-0 py-2 ${
                                    subscription.totalCreatedInterviewCount <
                                    (subscription.subscriptionData
                                      ?.mh_subscription_plan_id
                                      ?.liveInterviewCount ||
                                      StarterSubscriptionInterviewsLimit)
                                      ? "under-limit-plan-hld"
                                      : "limit-crossed-plan-hld"
                                  }`}
                                >
                                  <p
                                    className={`limitation-content me-3  ${
                                      subscription.totalCreatedInterviewCount <
                                      (subscription.subscriptionData
                                        ?.mh_subscription_plan_id
                                        ?.liveInterviewCount ||
                                        StarterSubscriptionInterviewsLimit)
                                        ? "under-limit"
                                        : "limit-crossed"
                                    }`}
                                  >
                                    <FontAwesomeIcon
                                      icon={faCircleCheck}
                                      className="me-2 circle-check"
                                    />
                                    {subscription.totalCreatedInterviewCount >
                                    (subscription.subscriptionData
                                      ?.mh_subscription_plan_id
                                      ?.liveInterviewCount ||
                                      StarterSubscriptionInterviewsLimit)
                                      ? subscription.subscriptionData
                                          ?.mh_subscription_plan_id
                                          ?.liveInterviewCount ||
                                        StarterSubscriptionInterviewsLimit
                                      : subscription.totalCreatedInterviewCount}
                                    /
                                    {subscription.subscriptionData
                                      ?.mh_subscription_plan_id
                                      ?.liveInterviewCount ||
                                      StarterSubscriptionInterviewsLimit}{" "}
                                    Interview created
                                  </p>
                                  <button
                                    className="upgrade-btn"
                                    onClick={() => setShowPlan(!showPlan)}
                                  >
                                    <img
                                      src={PremiumIcon}
                                      alt="premium"
                                      className="img-fluid me-2"
                                    />
                                    Upgrade to Professional
                                  </button>
                                </div>
                              ) : (
                                <></>
                              )}
                            </td>
                          </tr>

                          {/* List View */}
                          {isPageLoading ? (
                            Array.from({ length: 9 }, (e, i) => (
                              <tr
                                key={allInterviews._id}
                                className="interview-active"
                              >
                                <td className="px-3 py-3 p-1 interview-title">
                                  <LoadingShape />
                                </td>
                                <td className="px-3 py-3 user-icon-table">
                                  <LoadingShape />
                                </td>
                                <td className="px-3 py-3 p-1 interview-title-name   ">
                                  <LoadingShape />
                                </td>
                                <td className="px-3 py-3 scheduled-on-table">
                                  <LoadingShape />
                                </td>

                                <td className="px-3 py-3 no-of-rounds-table middle-align">
                                  <LoadingShape />
                                </td>
                                <td className="px-3 py-3  middle-align">
                                  <LoadingShape className="py-2" />
                                </td>

                                <td className="px-3 py-3 middle-align no-padding-copy-link">
                                  <LoadingShape />
                                </td>

                                <td className="px-3 py-3 middle-align">
                                  <LoadingShape />
                                </td>
                              </tr>
                            ))
                          ) : (
                            <>
                              {allInterviews.length > 0 &&
                                allInterviews.map((interviews, index) => (
                                 <>{interviews.interviewRounds&&<>
                                  <tr
                                    className={`interview-active ${
                                      interviewStatus === "pending"
                                        ? ``
                                        : `cardViewInterview`
                                    } `}
                                    key={`${index}interview-title`}
                                    onClick={() =>
                                      onCompletedInterviewChange(interviews)
                                    }
                                  >
                                    <td className="interview-title font-size">
                                      {interviewStatus == "completed" ? (
                                        <>
                                          {interviews.interviewRounds
                                            .candidateInterviewStatus ==
                                            "selected" ||
                                          interviews.interviewRounds
                                            .candidateInterviewStatus ==
                                            "passed" ? (
                                            <div className="d-inline-block">
                                              <>
                                                <img
                                                  className="interviewStatusImg me-2 img-fluid"
                                                  src={SelectedIcon}
                                                  alt="logo"
                                                  loading="lazy"
                                                />
                                              </>
                                            </div>
                                          ) : (
                                            <div className="d-inline-block">
                                              <>
                                                <img
                                                  className="interviewStatusImg me-2 img-fluid"
                                                  src={NotSelectIcon}
                                                  alt="logo"
                                                  loading="lazy"
                                                />
                                              </>
                                            </div>
                                          )}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                      {interviews.interviewType ===
                                        "instant" && (
                                        <img
                                          src={instantInterviewIcon}
                                          className="me-2 img-fluid"
                                          alt="logo"
                                          loading="lazy"
                                        />
                                      )}
                                      {`${interviews.interviewTitle}`}
                                    </td>

                                    <td className="user-icon-table">
                                      {interviews.candidateEmail !== "" &&
                                      interviews.candidateName !== "" &&
                                      !interviews.candidate ? (
                                        <p className="d-inline candidate-name">
                                          {interviews.candidateName
                                            ? interviews.candidateName.length >
                                              10
                                              ? interviews.candidateName.slice(
                                                  0,
                                                  10
                                                ) + "..."
                                              : interviews.candidateName
                                            : interviews.candidateEmail.split(
                                                "@"
                                              )[0].length > 10
                                            ? interviews.candidateEmail
                                                .split("@")[0]
                                                .slice(0, 10) + "..."
                                            : interviews.candidateEmail.split(
                                                "@"
                                              )[0]}
                                        </p>
                                      ) : (
                                        <span className="d-flex justify-content-center hypenTable">
                                          -
                                        </span>
                                      )}
                                    </td>
                                    {interviewStatus == "completed" ? (
                                      <td className="user-icon-table ratingStars">
                                        <ReactStars
                                          count={5}
                                          value={
                                            interviews.interviewRounds
                                              .interviewerFeedback &&
                                            interviews.interviewRounds
                                              .interviewerFeedback.overAll
                                              ? interviews.interviewRounds
                                                  .interviewerFeedback.overAll
                                                  .ratings
                                              : 0
                                          }
                                          size={16}
                                          activeColor="#FF9F4B"
                                          edit={false}
                                          color="#C4C4C4"
                                          className=""
                                        />
                                      </td>
                                    ) : (
                                      <></>
                                    )}

                                    <td className="scheduled-on-table">
                                      <div
                                        className={
                                          interviewStatus == "completed"
                                            ? "timings-card-div no-background-timings"
                                            : "timings-card-div p-2 pe-4"
                                        }
                                      >
                                        <ul className="timings-card-view ps-0">
                                          {interviews.currentRoundStatus ==
                                          "completed" ? (
                                            <>
                                              {/* Empty because it will create a vertical-align problem for the time */}
                                            </>
                                          ) : (
                                            <li className="awaiting-acceptance mb-2">
                                              {interviews.currentRoundStatus ==
                                              "completed" ? (
                                                <span
                                                  className={`${
                                                    interviews.interviewRounds
                                                      ?.acceptanceFromCandidate ==
                                                    "rejected"
                                                      ? "acceptancyDecline"
                                                      : interviews
                                                          .interviewRounds
                                                          ?.acceptanceFromCandidate ==
                                                        "accepted"
                                                      ? "acceptancyAccept"
                                                      : "acceptancyAwait"
                                                  }`}
                                                ></span>
                                              ) : (
                                                <>
                                                  {/* className={(interviews.interviewRounds.acceptanceFromCandidate == 'declined') ? 'red-icon'  : (interviewStatus  ==  'passed'  ||  interviewStatus ==  'completed')  ? 'green-cirlce'  : <></>} */}

                                                  <span
                                                    className={`${
                                                      interviews.interviewRounds
                                                        ?.acceptanceFromCandidate ==
                                                      "rejected"
                                                        ? "acceptancyDecline"
                                                        : interviews
                                                            .interviewRounds
                                                            ?.acceptanceFromCandidate ==
                                                          "accepted"
                                                        ? "acceptancyAccept"
                                                        : "acceptancyAwait"
                                                    }`}
                                                  >
                                                    {interviews.interviewRounds
                                                      ?.acceptanceFromCandidate ==
                                                    "rejected"
                                                      ? "Declined"
                                                      : interviews
                                                          .interviewRounds
                                                          ?.acceptanceFromCandidate ==
                                                        "accepted"
                                                      ? "Accepted"
                                                      : "Awaiting Acceptance"}
                                                  </span>
                                                </>
                                              )}
                                            </li>
                                          )}
                                          <div className="d-flex align-items-center interviewScheduleDate ">
                                            <li className="">
                                              {interviews.interviewRounds &&
                                                moment(
                                                  interviews.interviewRounds
                                                    ?.interviewStartTime
                                                ).format("h:mm")}{" "}
                                              -{" "}
                                              {interviews.interviewRounds &&
                                                moment(
                                                  interviews.interviewRounds
                                                    .interviewEndTime
                                                ).format("h:mm   a")}
                                            </li>
                                            <li className="interviewDateDot"></li>
                                            <li className="d">
                                              {interviews.interviewRounds &&
                                                moment(
                                                  interviews.interviewRounds
                                                    .interviewStartTime
                                                ).format("D MMM YYYY")}
                                            </li>
                                          </div>
                                        </ul>
                                      </div>
                                    </td>
                                    {isAllInterviews === "allInterviews" ? (
                                      <td className="interview-title-name">
                                        {interviews.interviewRounds?.interviewer
                                          ?.firstName &&
                                          interviews.interviewRounds.interviewer?.firstName
                                            .charAt(0)
                                            .toUpperCase() +
                                            interviews.interviewRounds.interviewer?.firstName
                                              .substr(1)
                                              .toLowerCase() +
                                            " " +
                                            (interviews.interviewRounds
                                              .interviewer?.lastName &&
                                              interviews.interviewRounds.interviewer?.lastName
                                                .charAt(0)
                                                .toUpperCase() +
                                                interviews.interviewRounds.interviewer?.lastName
                                                  .substr(1)
                                                  .toLowerCase())}
                                      </td>
                                    ) : (
                                      ""
                                    )}
                                    <td className="no-of-rounds-table font-size ">
                                      <button
                                        className={`no-of-rounds new-background NoOfRoundHld ${
                                          isAllInterviews !== "allInterviews"
                                            ? "roundBorderRadius"
                                            : ""
                                        } ${
                                          interviewStatus === "completed"
                                            ? "round-completed"
                                            : ""
                                        }`}
                                      >
                                        Round{" "}
                                        {interviews.interviewRounds &&
                                          interviews.interviewRounds.round}
                                      </button>
                                    </td>

                                    {interviewStatus != "completed" ? (
                                      <td className="middle-align  no-padding-copy-link">
                                        {
                                          // clicked button == index of the button clicked
                                          clickedButton == interviews._id ? (
                                            <button className=" copy-link-class copied ">
                                              <img
                                                src={LinkIcon}
                                                className="img-fluid"
                                                goto="link"
                                              />
                                              <span className="copy-link-text copied">
                                                Copied !
                                              </span>
                                            </button>
                                          ) : (
                                            <button
                                              className="copy-link-class "
                                              onClick={() =>
                                                onCopy(
                                                  interviews.interviewRounds
                                                    .interviewLink,
                                                  interviews._id
                                                )
                                              }
                                              id={interviews._id}
                                            >
                                              <img
                                                src={LinkIcon}
                                                className="img-fluid"
                                                goto="link"
                                              />
                                              <span className="copy-link-text ">
                                                Copy Link
                                              </span>
                                            </button>
                                          )
                                        }
                                      </td>
                                    ) : (
                                      " "
                                    )}

                                    <td
                                      className="middle-align pe-4"
                                      style={{
                                        textAlign: `${
                                          interviewStatus == "completed"
                                            ? "left"
                                            : "right"
                                        }`,
                                      }}
                                    >
                                      <button
                                        className={` reportBtn ${
                                          interviewStatus == "completed"
                                            ? interviews.interviewRounds
                                                .candidateInterviewStatus ===
                                                "selected" ||
                                              interviews.interviewRounds
                                                .candidateInterviewStatus ===
                                                "passed"
                                              ? "greenBtnBackground"
                                              : "redBtnBackground"
                                            : "startInterviewInactive"
                                        }
                                          `}
                                        style={{
                                          background: `${
                                            moment().isAfter(
                                              interviews.interviewRounds
                                                .interviewStartTime
                                            ) &&
                                            moment().isBefore(
                                              interviews.interviewRounds
                                                .interviewEndTime
                                            )
                                              ? ""
                                              : ""
                                          }`,
                                        }}
                                        onClick={(e) => {
                                          interviewStatus == "completed"
                                            ? viewReport(e, interviews)
                                            : startInterview(interviews);
                                        }}
                                      >
                                        <span className="">
                                          {interviewStatus == "completed"
                                            ? "View Report"
                                            : "Start"}
                                        </span>
                                        {interviews.interviewRounds
                                          ?.acceptanceFromCandidate ===
                                          "selected" ||
                                        interviewStatus == "completed" ? (
                                          <img
                                            src={
                                              interviewStatus == "completed"
                                                ? ViewReport
                                                : Interview
                                            }
                                            className="reportIcon ms-2 img-fluid"
                                            alt="logo"
                                            loading="lazy"
                                          />
                                        ) : (
                                          <div className="start-btn-img"></div>
                                        )}
                                        {/* <FaArrowRight /> */}
                                      </button>

                                      {interviewStatus == "completed" ? (
                                        <>
                                          <div
                                            id="three_dots"
                                            className="threeDotsdropdown px-3"
                                            style={{
                                              paddingLeft: "15px !important",
                                            }}
                                          >
                                            <button
                                              onClick={(e) => {
                                                myFunction(e, interviews._id);
                                              }}
                                              className="dropbtn three-dots-1 "
                                            >
                                              <BsThreeDotsVertical className="more-button" />
                                            </button>
                                            {tableMenuActive ===
                                              interviews._id && (
                                              <div
                                                id={
                                                  "myDropdown" + interviews._id
                                                }
                                                className="tableMenu show"
                                                ref={wrapperRef}
                                              >
                                                {interviewStatus == "pending" &&
                                                  interviews.interviewType ==
                                                    "scheduled" && (
                                                    <div
                                                      className="menuItem"
                                                      onClick={() =>
                                                        editSchedule(interviews)
                                                      }
                                                    >
                                                      <img
                                                        className="menuIcons img-fluid"
                                                        src={Edit}
                                                        alt="logo"
                                                        loading="lazy"
                                                      />
                                                      <p className="paragraph">
                                                        Edit Schedule
                                                      </p>
                                                    </div>
                                                  )}
                                                {interviewStatus ==
                                                  "completed" && (
                                                  <div
                                                    className="menuItem"
                                                    onClick={(e) => {
                                                      createAnotherRound(
                                                        e,
                                                        interviews
                                                      );
                                                    }}
                                                  >
                                                    <img
                                                      className="menuIcons img-fluid"
                                                      src={AddActive}
                                                      alt="logo"
                                                      loading="lazy"
                                                    />
                                                    <p className="paragraph pe-3">
                                                      Add Next Round
                                                    </p>
                                                  </div>
                                                )}
                                              </div>
                                            )}
                                          </div>
                                        </>
                                      ) : (
                                        <div
                                          id="three_dots"
                                          className="threeDotsdropdown padding-left"
                                          style={{
                                            paddingLeft: "15px !important",
                                          }}
                                        >
                                          <button
                                            onClick={(e) =>
                                              myFunction(e, interviews._id)
                                            }
                                            className="dropbtn three-dots-1 "
                                          >
                                            <BsThreeDotsVertical className="more-button" />
                                          </button>
                                          {tableMenuActive ===
                                            interviews._id && (
                                            <div
                                              id={"myDropdown" + interviews._id}
                                              className={`tableMenu show ${
                                                interviewStatus == "pending" &&
                                                "positionDelete"
                                              }`}
                                              ref={wrapperRef}
                                            >
                                              {interviewStatus == "pending" &&
                                                interviews.interviewType ==
                                                  "scheduled" && (
                                                  <div
                                                    className="menuItem"
                                                    onClick={() =>
                                                      editSchedule(interviews)
                                                    }
                                                  >
                                                    <img
                                                      className="menuIcons img-fluid"
                                                      src={Edit}
                                                      alt="logo"
                                                      loading="lazy"
                                                    />
                                                    <p className="paragraph">
                                                      Edit Schedule
                                                    </p>
                                                  </div>
                                                )}
                                              <div
                                                className="menuItem"
                                                onClick={() =>
                                                  deleteInterview(interviews)
                                                }
                                              >
                                                <img
                                                  className="menuIcons img-fluid"
                                                  src={Delete}
                                                  alt="logo"
                                                  loading="lazy"
                                                />
                                                <p className="paragraph">
                                                  Delete Interview
                                                </p>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                 </>}</>
                                ))}
                            </>
                          )}
                          {/* List View */}
                        </tbody>
                      </table>
                      {isAuthenticated()?.role !== "admin_panel" &&
                      subscription.totalCreatedInterviewCount >
                        (subscription.subscriptionData?.mh_subscription_plan_id
                          ?.liveInterviewCount ||
                          StarterSubscriptionInterviewsLimit) ? (
                        <table className="table mb-0">
                          <div className="position-relative masking-data-hld">
                            <thead className="visibility-hidden">
                              <tr key={"allInterviews"}>
                                <th scope="col" className="list-table-heading">
                                  INTERVIEW TITLE
                                </th>
                                <th scope="col" className="list-table-heading">
                                  CANDIDATE NAME
                                </th>

                                <th scope="col" className="list-table-heading">
                                  SCHEDULED ON
                                </th>
                                <th scope="col" className="list-table-heading">
                                  NO. OF ROUNDS
                                </th>
                                <th
                                  scope="col"
                                  className="list-table-heading"
                                ></th>
                              </tr>
                            </thead>
                            {Array.from({
                              length: subscription.totalCreatedInterviewCount,
                            }).map((_, i) => (
                              <tr
                                className={`interview-active`}
                                key={`${i}interview-title`}
                              >
                                <td className="interview-title font-size">
                                  Interview Title
                                </td>

                                <td className="user-icon-table">
                                  <p className="d-inline candidate-name">
                                    @username
                                  </p>
                                </td>

                                <td className="scheduled-on-table">
                                  <div className="timings-card-div p-2 pe-4">
                                    <ul className="timings-card-view ps-0">
                                      <li className="awaiting-acceptance mb-2">
                                        <span className={`acceptancyAwait`}>
                                          Awaiting Acceptance
                                        </span>
                                      </li>
                                      <div className="d-flex align-items-center interviewScheduleDate ">
                                        <li className="">
                                          {moment().format("h:mm")}-
                                          {moment().format("h:mm   a")}
                                        </li>
                                        <li className="interviewDateDot"></li>
                                        <li className="d">
                                          {moment().format("D MMM YYYY")}
                                        </li>
                                      </div>
                                    </ul>
                                  </div>
                                </td>
                                <td className="interview-title-name">
                                  Interviewer name
                                </td>
                                <td className="no-of-rounds-table font-size ">
                                  <button
                                    className={`no-of-rounds new-background NoOfRoundHld`}
                                  >
                                    Round
                                  </button>
                                </td>

                                <td className="middle-align  no-padding-copy-link">
                                  <button className="copy-link-class ">
                                    <img
                                      src={LinkIcon}
                                      className="img-fluid"
                                      goto="link"
                                    />
                                    <span className="copy-link-text ">
                                      Copy Link
                                    </span>
                                  </button>
                                </td>

                                <td
                                  className="middle-align pe-4"
                                  style={{
                                    textAlign: "right",
                                  }}
                                >
                                  <button
                                    className={`reportBtn startInterviewInactive`}
                                  >
                                    <span className="">Start</span>
                                  </button>
                                </td>
                              </tr>
                            ))}
                            <div className="mask-data mx-0 mb-0">
                              <div className="mask-data-upgrade-hld pe-0">
                                <img
                                  src={LockIcon}
                                  alt="locked"
                                  className="img-fluid"
                                />
                                <p className="mx-4">
                                  It appears that your plan has been downgraded,
                                  and there's been a change <br />
                                  in the access limit for assessments.
                                </p>
                                <button
                                  className="d-flex align-items-center Upgrade-btn"
                                  onClick={() => setShowPlan(!showPlan)}
                                >
                                  <img
                                    src={BlackCrown}
                                    alt="premium"
                                    className="img-fluid me-2"
                                  />{" "}
                                  Upgrade
                                </button>
                              </div>
                            </div>
                          </div>
                        </table>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    {isPageLoading ? (
                      <div
                        className="d-flex justify-content-center"
                        style={{ height: "calc(100vh - 300px)" }}
                      >
                        <div
                          type="button"
                          className="py-5 align-self-center text-center addInterview add-button-card"
                          onClick={handleShow}
                          style={{ height: "170px" }}
                        >
                          <div className="col-6 col-md-4 col-lg-3 loaderCard">
                            <p className="m-1 mt-5 w-20">
                              <LoadingShape className="loadingPara" />
                            </p>
                            <p className="w-30 mt-3">
                              <LoadingShape className="loadingPara" />
                            </p>
                            <p className="w-50 mt-3">
                              <LoadingShape className="loadingPara" />
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <NoInterview
                        handleShow={handleShow}
                        CreateInterview={CreateInterview}
                        subscription={subscription}
                        StarterSubscriptionInterviewsLimit={
                          StarterSubscriptionInterviewsLimit
                        }
                      />
                    )}
                  </>
                )}
              </div>

              {/* Card View START */}
              {isPageLoading ? (
                <div id="card" className="tabcontent cardViewHld">
                  <div className="row m-4">
                    {Array.from(
                      {
                        length: 12,
                      },
                      (_, i) => (
                        <div className="col-6 col-md-4 col-lg-3 loaderCard">
                          <p className="m-1 mt-5 w-20">
                            <LoadingShape className="loadingPara" />
                          </p>
                          <p className="w-30 mt-3">
                            <LoadingShape className="loadingPara" />
                          </p>
                          <p className="w-50 mt-3">
                            <LoadingShape className="loadingPara" />
                          </p>
                        </div>
                      )
                    )}
                  </div>
                </div>
              ) : (
                <div id="card" className="tabcontent cardViewHld">
                  {allInterviews.length > 0 ? (
                    <div className="row mx-4">
                      {allInterviews.map((item, index) => (
                        <div
                          className="col-6 col-md-4 col-lg-3 pe-2"
                          key={`myiterview${index}`}
                        >
                          <div
                            className={
                              isAllInterviews == "myInterviews"
                                ? "interviewCardHld my-2 custom-height"
                                : "interviewCardHld my-2"
                            }
                          >
                            <div className="d-flex justify-content-between pt-3 px-3 pb-2 align-items-center">
                              <div
                                className={`d-flex ${
                                  interviewStatus === "completed" &&
                                  "interviewStatusCardView"
                                }`}
                              >
                                {interviewStatus === "completed" && (
                                  <div className="">
                                    {item.interviewRounds
                                      .candidateInterviewStatus == "selected" ||
                                    item.interviewRounds
                                      .candidateInterviewStatus == "passed" ? (
                                      <>
                                        <img
                                          className="interviewStatusImgCard img-fluid"
                                          src={SelectedIcon}
                                          alt="logo"
                                          loading="lazy"
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <img
                                          className="interviewStatusImgCard img-fluid"
                                          src={NotSelectIcon}
                                          alt="logo"
                                          loading="lazy"
                                        />
                                      </>
                                    )}
                                    {/* </p> */}
                                  </div>
                                )}
                                <button className="roundsInCardView">
                                  Round{" "}
                                  {item.interviewRounds &&
                                    item.interviewRounds.round}
                                </button>
                              </div>

                              <div>
                                <div id="three_dots" className="dropdown m-0">
                                  <button
                                    onClick={() => setTableMenuActive(item._id)}
                                    className="horizontalThreeDot"
                                  >
                                    <img
                                      src={threeDotsHorizontal}
                                      className="HThreeDots img-fluid"
                                      alt="logo"
                                      loading="lazy"
                                    />
                                  </button>
                                  {tableMenuActive === item._id &&
                                    viewText === "card" && (
                                      <div
                                        id={"cardViewDropdown" + item._id}
                                        className="dropdown-content menu-show show"
                                        ref={wrapperRef}
                                      >
                                        {interviewStatus == "pending" &&
                                        item.interviewType == "scheduled" ? (
                                          <button
                                            className="menu-btn text-left"
                                            onClick={() => editSchedule(item)}
                                          >
                                            <img
                                              className="menuIcons img-fluid"
                                              src={Edit}
                                              alt="logo"
                                              loading="lazy"
                                            />
                                            Edit Schedule
                                          </button>
                                        ) : (
                                          <></>
                                        )}

                                        {interviewStatus !== "completed" && (
                                          <button
                                            className="menu-btn text-left"
                                            onClick={() =>
                                              deleteInterview(item)
                                            }
                                          >
                                            <img
                                              className="menuIcons img-fluid"
                                              src={Delete}
                                              alt="logo"
                                              loading="lazy"
                                            />
                                            Delete Interview
                                          </button>
                                        )}
                                        {interviewStatus == "completed" && (
                                          <button
                                            className="menu-btn"
                                            onClick={(e) =>
                                              createAnotherRound(e, item)
                                            }
                                          >
                                            <img
                                              className="menuIcons img-fluid"
                                              src={AddActive}
                                              alt="logo"
                                              loading="lazy"
                                            />
                                            Add Next Round
                                          </button>
                                        )}
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                            <h6 className="jobRole text-left px-3">
                              {item.interviewType === "instant" && (
                                <img
                                  src={instantInterviewIcon}
                                  className="me-2 img-fluid"
                                  alt="logo"
                                  loading="lazy"
                                />
                              )}
                              {`${
                                item.interviewTitle.length > 20
                                  ? item.interviewTitle.slice(0, 20) + "..."
                                  : item.interviewTitle
                              }`}
                            </h6>

                            <div className="d-flex ps-3 pe-2 pb-2 pt-1">
                              <div
                                className={
                                  interviewStatus == "completed"
                                    ? "timings-card-div no-background-timings"
                                    : "timings-card-div py-1"
                                }
                              >
                                <ul className="timings-card-view text-left py-2">
                                  {item.currentRoundStatus == "completed" ? (
                                    <>
                                      {/* Empty because it will create a vertical-align problem for the time */}
                                    </>
                                  ) : (
                                    <li className="awaiting-acceptance py-2">
                                      {item.currentRoundStatus ==
                                      "completed" ? (
                                        <span
                                          className={`${
                                            item.interviewRounds
                                              ?.acceptanceFromCandidate ==
                                            "rejected"
                                              ? "acceptancyDecline"
                                              : item.interviewRounds
                                                  ?.acceptanceFromCandidate ==
                                                "accepted"
                                              ? "acceptancyAccept"
                                              : "acceptancyAwait"
                                          }`}
                                        ></span>
                                      ) : (
                                        <>
                                          {/* className={(interviews.interviewRounds.acceptanceFromCandidate == 'declined') ? 'red-icon'  : (interviewStatus  ==  'passed'  ||  interviewStatus ==  'completed')  ? 'green-cirlce'  : <></>} */}
                                          <span
                                            className={`${
                                              item.interviewRounds
                                                ?.acceptanceFromCandidate ==
                                              "rejected"
                                                ? "acceptancyDecline"
                                                : item.interviewRounds
                                                    ?.acceptanceFromCandidate ==
                                                  "accepted"
                                                ? "acceptancyAccept"
                                                : "acceptancyAwait"
                                            }`}
                                          >
                                            {item.interviewRounds
                                              ?.acceptanceFromCandidate ==
                                            "rejected"
                                              ? "Declined"
                                              : item.interviewRounds
                                                  ?.acceptanceFromCandidate ==
                                                "accepted"
                                              ? "Accepted"
                                              : "Awaiting Acceptance"}
                                          </span>
                                        </>
                                      )}
                                    </li>
                                  )}
                                  <div className="d-flex align-items-center interviewScheduleDate">
                                    <li className="">
                                      {item.interviewRounds &&
                                        moment(
                                          item.interviewRounds
                                            ?.interviewStartTime
                                        ).format("h:mm")}
                                      &nbsp;-&nbsp;
                                      {item.interviewRounds &&
                                        moment(
                                          item.interviewRounds.interviewEndTime
                                        ).format("h:mm   a")}
                                    </li>
                                    <li className="interviewDateDot "></li>
                                    <li className="">
                                      {item.interviewRounds &&
                                        moment(
                                          item.interviewRounds
                                            ?.interviewStartTime
                                        ).format("D MMM YYYY")}
                                    </li>
                                  </div>
                                </ul>
                              </div>
                            </div>
                            {isAllInterviews === "allInterviews" ? (
                              <hr className="mx-3" />
                            ) : (
                              <></>
                            )}
                            <span className="CandidateSection px-3">
                              <p className="CandidateTitle">Candidate</p>
                              <p className="candidateName ms-2">
                                <div className="user-icon-table p-0">
                                  {item.candidateEmail != "" &&
                                  item.candidateName != "" &&
                                  !item.candidate ? (
                                    <></>
                                  ) : item.candidate ? (
                                    <p className="d-inline p-1"></p>
                                  ) : (
                                    <span className="ms-4">-</span>
                                  )}
                                  <p className="d-inline ">
                                    {item.candidateName
                                      ? item.candidateName.length > 10
                                        ? item.candidateName.slice(0, 10) +
                                          "..."
                                        : item.candidateName
                                      : item.candidateEmail.split("@")[0]
                                          .length > 10
                                      ? item.candidateEmail
                                          .split("@")[0]
                                          .slice(0, 10) + "..."
                                      : item.candidateEmail.split("@")[0]}
                                  </p>
                                </div>
                              </p>
                            </span>
                            <div className="CandidateSection px-3">
                              {isAllInterviews === "allInterviews" ? (
                                <>
                                  <p className="CandidateTitle">
                                    Interviewer Name
                                  </p>

                                  <p className="candidateName mt-2 ms-2">
                                    {item.interviewRounds?.interviewer
                                      ?.firstName &&
                                      item.interviewRounds.interviewer?.firstName
                                        .charAt(0)
                                        .toUpperCase() +
                                        item.interviewRounds.interviewer?.firstName
                                          .substr(1)
                                          .toLowerCase() +
                                        " " +
                                        (item.interviewRounds.interviewer
                                          ?.lastName &&
                                          item.interviewRounds.interviewer?.lastName
                                            .charAt(0)
                                            .toUpperCase() +
                                            item.interviewRounds.interviewer?.lastName
                                              .substr(1)
                                              .toLowerCase())}
                                  </p>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                            <div className="d-flex align-items-center  pb-3 mt-3 ps-3">
                              <div className="d-flex align-items-center">
                                {item.currentRoundStatus == "pending" && (
                                  <button
                                    className="start-button "
                                    style={{
                                      background: `${
                                        moment().isAfter(
                                          item.interviewRounds
                                            ?.interviewStartTime
                                        ) &&
                                        moment().isBefore(
                                          item.interviewRounds.interviewEndTime
                                        )
                                          ? ""
                                          : ""
                                      }`,
                                    }}
                                    onClick={() => startInterview(item)}
                                  >
                                    Start Interview{" "}
                                    <div className="start-btn-img"></div>
                                  </button>
                                )}
                                {item.currentRoundStatus == "completed" && (
                                  <button
                                    className={` reportBtn ${
                                      (interviewStatus == "completed" &&
                                        item.interviewRounds
                                          .candidateInterviewStatus ==
                                          "selected") ||
                                      item.interviewRounds
                                        .candidateInterviewStatus == "passed"
                                        ? "greenBtnBackground"
                                        : "redBtnBackground"
                                    }
                                    `}
                                    style={{
                                      background: `${
                                        moment().isAfter(
                                          item.interviewRounds
                                            ?.interviewStartTime
                                        ) &&
                                        moment().isBefore(
                                          item.interviewRounds.interviewEndTime
                                        )
                                          ? ""
                                          : ""
                                      }`,
                                    }}
                                    onClick={(e) => viewReport(e, item)}
                                  >
                                    View Report{" "}
                                    <img
                                      src={ViewReport}
                                      className="reportIcon ms-2 img-fluid"
                                      alt="logo"
                                      loading="lazy"
                                    />
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <NoInterview
                      handleShow={handleShow}
                      CreateInterview={CreateInterview}
                      subscription={subscription}
                      StarterSubscriptionInterviewsLimit={
                        StarterSubscriptionInterviewsLimit
                      }
                    />
                  )}
                </div>
              )}
              {/* Card View END */}

              {/* Pagination STARTS here */}
              {numOfInterviews > 10 && (
                <div className="text-center my-2 ">
                  <Pagination
                    className="rainbow-m_auto"
                    pages={totalPages}
                    activePage={activePage}
                    onChange={handlePaginationChange}
                    variant="shaded"
                  />
                </div>
              )}
              {/* Pagination ENDS here */}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        size="sm"
        centered
        dialogClassName={"createInterviewLink"}
        enforceFocus={false}
        className="createInterviewModal"
      >
        <Modal.Header>
          <div className="d-flex justify-content-between p-1 w-100">
            <h5>
              {/* {createRound == true ? (
                "Create another Round"
              ) : (
                <>{isEditable ? "Edit an Interview" : "Create an Interview"}</>
              )} */}
            </h5>
            <img
              src={Cross}
              onClick={handleClose}
              className="img-fluid cursor-pointer"
              style={{ width: "15px", height: "15px" }}
              alt="logo"
              loading="lazy"
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          {modalInterview === 0 && (
            <div className="text-center">
              <h4 className="modalHeading">Create an Interview</h4>
              <img
                src={createInterviewImg}
                className="img-fluid"
                alt="logo"
                loading="lazy"
              />
              <button
                className="mt-4 mb-3 instantInterview"
                onClick={createInstantInterview}
              >
                Create an Instant Interview
              </button>
              <p className="orText">(or)</p>
              <button
                className="mt-3 mb-4 scheduleInterview"
                onClick={() => generateScheduledLink()}
              >
                Schedule an Interview
              </button>
            </div>
          )}
          {modalInterview === "instant" && (
            <div className="text-center instant">
              <h4 className="modalHeading">Share your Interview link</h4>
              <div className="copyLinkBox">
                <img
                  src={interviewLink}
                  className="linkIconModal img-fluid"
                  alt="logo"
                  loading="lazy"
                />
                <input
                  className="copyLink"
                  value={instantLink}
                  readOnly={isEditable || createRound}
                  disabled
                />
                <button
                  className={`copyBtn text-center ${
                    isCopied !== "schedule" ? "" : "greenBackground"
                  }`}
                  onClick={() => copyLink("schedule")}
                >
                  <p>{isCopied !== "schedule" ? "Copy" : "Copied!"}</p>
                </button>
              </div>
              <p className="my-4 mb-4 py-3">
                Anyone with the link can attend the interview
              </p>
              <div className="shareInterview mb-3">
                <a href={`mailto:""?body=${instantLink}`} target="_blank">
                  <img
                    className="mt-0 mx-3 shareInterviewEmail img-fluid"
                    src={Email}
                    alt="logo"
                    loading="lazy"
                  />
                </a>
                <a
                  href={`https://www.linkedin.com/shareArticle?url=${instantLink}`}
                  target="_blank"
                >
                  <img
                    className="mt-0 mx-3 shareInterviewWhatsapp img-fluid"
                    src={linkedInIcon}
                    alt="logo"
                    loading="lazy"
                  />
                </a>
              </div>
            </div>
          )}
          {modalInterview === "schedule" && (
            <div className="text-center schedule">
              <h4 className="modalHeading">Schedule an Interview</h4>
              <div className="copyLinkBox">
                <img
                  src={interviewLink}
                  className="linkIconModal img-fluid"
                  alt="logo"
                  loading="lazy"
                />
                <input
                  className="copyLink"
                  value={instantLink}
                  readOnly={isEditable || createRound}
                  disabled
                />
                <button
                  className={`copyBtn text-center ${
                    isCopied !== "schedule" ? "" : "greenBackground"
                  }`}
                  onClick={() => copyLink("schedule")}
                >
                  <p>{isCopied !== "schedule" ? "Copy" : "Copied!"}</p>
                </button>
              </div>

              <div className="mt-3 schedulingDiv">
                <h6>Interview Title</h6>
                <input
                  className={`scheduleInputs p-2  ${
                    createRound ? "disabled-class" : ""
                  }`}
                  placeholder="Enter Interview Title"
                  onChange={oninterviewTitleChange}
                  value={interviewTitle}
                  readOnly={createRound}
                />
                {interviewTitleError && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "12px",
                      fontWeight: "400",
                    }}
                  >
                    This Field is required
                  </p>
                )}
                {/* Date Picker Here START */}

                <h6 className="">Select Date</h6>
                <div className="datepicker">
                  <img
                    src={DateIcon}
                    className="img-fluid dateIconImg "
                    alt="logo"
                    loading="lazy"
                  />

                  <Datetime
                    timeFormat={false}
                    closeOnSelect={true}
                    onChange={handleDateChange}
                    isValidDate={valid}
                    dateFormat="DD MMM YYYY"
                    value={selectedDate}
                  />
                  <img
                    src={dropdownArrow}
                    className="img-fluid dropdownArrowImg"
                    alt="logo"
                    loading="lazy"
                  />
                  {/* Date Picker Here End */}
                </div>

                {/* <ComboDatePicker/> */}

                <div className="d-flex justify-content-between ">
                  <div className="d-flex flex-column">
                    <label className="timerLabels mb-3">Start time</label>
                    <div className="timePicker allInterviewTimePicker">
                      <img
                        src={Clock}
                        className="interviewClock img-fluid"
                        alt="logo"
                        loading="lazy"
                      />
                      <TimePicker
                        popupClassName="allInterviewScheduleTimePicker"
                        value={startTime}
                        minuteStep={5}
                        use12Hours={true}
                        showSecond={false}
                        onChange={handleTimeChange}
                        // onOpen={handleTimeClick}
                        defaultValue={moment()}
                        clearText=""
                        clearIcon=""
                      />
                      <img
                        src={dropdownArrow}
                        className="timePickerArrows img-fluid"
                        alt="logo"
                        loading="lazy"
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-column">
                    <label className="timerLabels mb-3">End time</label>
                    <div className="timePicker">
                      <img src={Clock} className="interviewClock" />
                      <TimePicker
                        value={endTime}
                        minuteStep={5}
                        use12Hours={true}
                        showSecond={false}
                        onChange={handleEndTimeChange}
                      />
                      <img
                        src={dropdownArrow}
                        className="timePickerArrows img-fluid"
                        alt="logo"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>

                {!createRound && (
                  <div className="mt-4 mb-2 d-flex emailSelection">
                    <div className="d-flex w-50 align-items-center">
                      <input
                        id="email"
                        type="radio"
                        className="me-3"
                        name="emailSelection"
                        onClick={(e) => {
                          setMailList("email");
                        }}
                        checked={mailList === "email"}
                      />
                      <span>Email ID</span>
                    </div>
                    <div className="d-flex w-50 align-items-center">
                      <input
                        type="radio"
                        className="me-3"
                        name="emailSelection"
                        onClick={(e) => {
                          setMailList("MHList");
                        }}
                        checked={mailList === "MHList"}
                      />
                      <span>From MH List</span>
                    </div>
                  </div>
                )}
                {(mailList === "email" || createRound) && (
                  <>
                    <div className="postion-relative">
                      <h6 className={`mb-1 ${createRound ? "mt-4" : ""}`}>
                        Candidate Email
                      </h6>
                      <input
                        className={`scheduleInputs mt-2 p-2  ${
                          createRound ? "disabled-class" : ""
                        }`}
                        onChange={onCandidateEmailChange}
                        value={candidateEmail}
                        placeholder="Enter Candidate Email"
                        readOnly={createRound}
                      />
                      {ValidateEmail(candidateEmail) && (
                        <img src={correctIcon} className="verified-icon" />
                      )}
                    </div>
                    {candidateEmailError !== "" && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "12px",
                          fontWeight: "400",
                        }}
                      >
                        {candidateEmailError}
                      </p>
                    )}
                  </>
                )}
                {mailList === "MHList" && (
                  <div style={{ color: "#e4e4e4" }}>
                    <h6>Candidate Name</h6>
                    <div className="mt-2 MhUserSearch">
                      <Select
                        styles={customStyles}
                        value={selectedCandidate}
                        onChange={handlenextInterviewerChange}
                        options={Mhlist}
                        placeholder="Search for MH users"
                      />
                      <img src={searchIcon} className="searchIconModal" />
                    </div>
                  </div>
                )}
              </div>
              <div className="px-3">
                {interviewTitle === "" || candidateEmail === "" ? (
                  <button className="my-4 scheduleInterviewlg greyScheduleBtn ">
                    {createRound == true ? (
                      "Create another Round"
                    ) : (
                      <>
                        {isEditable
                          ? "Edit an Interview"
                          : "Schedule an Interview"}
                      </>
                    )}
                  </button>
                ) : (
                  <button
                    className="my-4 scheduleInterviewlg"
                    // onClick={() => setModalInterview("")}
                    onClick={() => createScheduledInterview()}
                  >
                    {createRound == true ? (
                      "Create another Round"
                    ) : (
                      <>
                        {isEditable
                          ? "Edit an Interview"
                          : "Schedule an Interview"}
                      </>
                    )}
                  </button>
                )}
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
      <Modal
        show={showMsg}
        onHide={handleMsgClose}
        size="sm"
        centered
        dialogClassName={"createInterviewLink"}
        enforceFocus={false}
        className="createInterviewModal"
      >
        <Modal.Header>
          <div className="d-flex justify-content-between p-1 w-100">
            <img
              src={Cross}
              onClick={handleMsgClose}
              className="img-fluid ms-auto cursor-pointer"
              style={{ width: "15px", height: "15px" }}
              alt="logo"
              loading="lazy"
            />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <img
              src={greenTick}
              className="img-fluid mx-auto"
              alt="logo"
              loading="lazy"
            />
            <h4 className="modalHeading mt-3">Interview Scheduled</h4>
            <div className="scheduledInterviewBoxMain">
              <div className=" d-flex justify-content-between scheduledInterviewBox">
                <div className="w-50 text-left">
                  <p className="heading9">User</p>
                  <p className="subHeading9 ">{candidateEmail}</p>
                </div>
                <div className="w-50 text-left">
                  <p className="heading9">Interview Title</p>
                  <p className="subHeading9 ">{interviewTitle}</p>
                </div>
              </div>

              <div className="mb-4">
                <h6 className="heading9 my-4">
                  <img
                    src={interviewLink}
                    className="me-3 img-fluid"
                    alt="logo"
                    loading="lazy"
                  />
                  Interview Link
                </h6>
                <div className="d-flex linkCopySection">
                  <a
                    className="linkStyle"
                    onClick={() => {
                      copyLink("schedule");
                      setCopied(true);
                    }}
                  >
                    {instantLink.slice(0, 35) + "..."}
                  </a>
                  {copied && <button className="copiedModal">Copied !</button>}
                </div>
              </div>
              <hr />
              <div className="d-flex justify-content-around p-4 position-relative">
                <div className="d-flex justify-content-center timesInterview align-items-center">
                  <img
                    src={pinkBgCalendar}
                    className="img-fluid"
                    alt="logo"
                    loading="lazy"
                  />
                  <span className="InterviewTimings">
                    {moment(startTime).format("D MMM YYYY")}
                  </span>
                </div>
                <div className="verticalLine"></div>
                <div>
                  <img
                    src={pinkBgClock}
                    className="me-3 img-fluid"
                    alt="logo"
                    loading="lazy"
                  />
                  <span className="InterviewTimings">
                    {moment(startTime).format("h:mm  a")} {"-"}{" "}
                    {moment(endTime).format("h:mm  a")}
                  </span>
                </div>
              </div>
            </div>
            <p className="msgInfo">
              Please check your email.
              <br />
              We have a send a confirmation mail as well.
            </p>
            <button
              className="scheduleInterviewlg w-75 mx-auto mb-4"
              onClick={handleMsgClose}
            >
              Go to your Schedule
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <SubscriptionPlanScreen
        showPlan={showPlan}
        setShowPlan={setShowPlan}
        showContactUs={showContactUs}
        setShowContactUs={setShowContactUs}
        titleType="feature"
      />
      <ContactUs
        showContactUs={showContactUs}
        setShowContactUs={setShowContactUs}
        type="pricing"
      />
      <CreateAssessmentWithWarningPopup
        showCreateAssessmentWithWarningPopup={
          showCreateInterviewWithWarningPopup
        }
        setShowCreateAssessmentWithWarningPopup={
          setShowCreateInterviewWithWarningPopup
        }
        showPlan={showPlan}
        setShowPlan={setShowPlan}
        setShowBuildAssessmentModal={() => {
          setShow(true);
          setIsEditable(false);
        }}
      />
    </div>
  );
}
